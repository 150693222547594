import React, { FC, HTMLAttributes, useEffect } from "react";
import { useRouter } from "next/router";
import { Chain, useNetwork } from "wagmi";
import { goerli, mainnet, polygonMumbai, polygon } from "wagmi/chains";
import { ProfileButton } from "@dustlabs/profiles/evm";
import { useDeId, useDeIdModal } from "@dustlabs/profiles/core";
import { AddBtcWalletButton, BtcWalletProvider } from "@dustlabs/profiles/btc";
import { ProfileWalletNetwork } from "@dustlabs/profiles/types";
import { CircularProgress } from "@dustlabs/web/core";
import { DeGodsLogo, Y00tsLogo } from "@dustlabs/web/icons";
import { useWindowSize } from "@dustlabs/web/hooks";
import { GLOBAL_CONFIG } from "@shared/constants";

interface Props extends HTMLAttributes<HTMLDivElement> {
  variant?: "tiny" | "small";
}

const ConnectButton: FC<Props> = ({
  variant,
  className,
  ...componentProps
}: Props) => {
  const router = useRouter();
  const network = useNetwork();

  const [winWidth] = useWindowSize();
  const deId = useDeId();
  const { isSignedIn, authAccess, isLoadingAuthentication } = deId;
  const deIdModal = useDeIdModal();

  const getChains = () => {
    switch (process.env.NEXT_PUBLIC_EVM_CHAIN) {
      case "mainnet":
        return [mainnet];
      case "matic":
        return [polygon];
      case "goerli":
        return [goerli];
      case "mumbai":
        return [polygonMumbai];
      default:
        return [mainnet, polygon];
    }
  };

  const chains = [mainnet, polygon] as Chain[];

  useEffect(() => {
    import("@lottiefiles/lottie-player");
  }, []);

  return (
    <ProfileButton
      deId={deId}
      deIdModal={deIdModal}
      className={`${
        network?.chain?.id &&
        !chains.map((chain) => chain.id).includes(network?.chain?.id)
          ? ""
          : "[&_.profileButton]:bg-darkBackground"
      } [&_.profileButton]:text-darkFontPrimary ${className}`}
      options={{
        size: variant ?? (winWidth < 1280 ? "tiny" : "small"),
        socialDescription: {
          TWITTER: `Get followed by ${GLOBAL_CONFIG.TWITTER_HANDLE} for linking your twitter`,
          DISCORD: "Get access to the DeXYZ server and holder channels",
        },
        ...(isSignedIn && {
          additionalMenuItems: [
            {
              text: "My Holdings",
              icon: isLoadingAuthentication ? (
                <CircularProgress
                  className={`ml-2 first:[&_path]:fill-none fill-[#777E90]`}
                />
              ) : GLOBAL_CONFIG.ID === "degods" ? (
                <DeGodsLogo width={16} height={16} />
              ) : (
                <Y00tsLogo width={16} height={16} />
              ),
              onClick: authAccess(() => {
                if (router.pathname !== "/holdings") router.push(`/holdings`);
              }),
            },
          ],
        }),
      }}
      {...componentProps}
    />
  );
};

export default ConnectButton;
